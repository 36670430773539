import { allProducts } from "@/api";
import { IProduct, Product } from "@/lib/interfaces/IProduct";
import { inter, poppins } from "@/styles/font";
import { Box, Button, Flex, Grid, HStack, Text } from "@chakra-ui/react";
import { AxiosError } from "axios";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";

const Loader = dynamic(() => import('@/component/loader/Loader'))

export const ProductItem = ({ item, onItemClick }: {
  item: Product, onItemClick: (
    categoryId: string, marketId: string, shopId: string,
    productId: string, subCatId: string, stateId: string,
  ) => void
}) => {
  return (
    <Flex
      flexDirection={"column"}
      rounded={"md"}
      _hover={{ cursor: 'pointer', transform: "scale(1.02)" }}
      transition={"all .3s"}
      onClick={() =>
        onItemClick(item.subcategory[0].category, item.market,
          item.store, item._id, item.subcategory[0]._id, item.state)}
    >

      <Box position={'relative'} rounded={'md'} overflow={'hidden'} boxSize={{ base: '140px', lg: '140px' }}>
        <Image src={item.image[0]} fill alt={item.name} />
      </Box>
      <Text
        fontSize={{ base: "12px", lg: "md" }}
        fontWeight={"medium"}
        color={"martlinegray.900"}
        mt={{ base: "4px", lg: "8px" }}
        className={inter.className}
        noOfLines={1}
        textOverflow={'ellipsis'}
      >
        {item.caption}
      </Text>
      <Text
        fontSize={{ base: "14px", lg: "18px" }}
        fontWeight={"semibold"}
        color={"martlinegray.900"}
        className={poppins.className}
      >
        {`₦${new Intl.NumberFormat().format(Number(item.price))}`}
      </Text>
    </Flex>
  );
};

const Products = () => {
  const [page, setPage] = useState(1);
  const router = useRouter();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
  } = useQuery<IProduct, AxiosError>(['products', page], () => allProducts(page), {
    keepPreviousData: true
  });

  const handleRouteClick = (categoryId: string, marketId: string, shopId: string, productId: string,
    subCatId: string, stateId: string
  ) => {

    router.push({
      pathname:
        `/catalog/[categoryId]/[marketId]/shops/[shopId]/[productId]`,
      query: {
        categoryId, marketId, shopId, productId, stateId, subCatId
      }
    });
  }

  const lastPage = () => setPage(data?.total ?? 0 / 10);  // 10 -> is page size
  const firstPage = () => setPage(1);

  const buttonRange = useMemo(() => {
    // const totalPages = Math.ceil(data?.total ?? 0 / 10);
    const totalPages = 10;
    if (page === 1) {
      return new Array(3).fill(0).map((_, index) => (index + 1)) as number[];
    } else if (page === totalPages) {
      const arr = new Array(3).fill(0);
      for (let i = 2, j = 0; i >= 0; i--, j++) {
        arr[i] = totalPages - j;
      }
      return arr as number[];
    } else {
      const arr = new Array(3);
      arr[0] = page - 1;
      arr[1] = page;
      arr[2] = page + 1;
      return arr as number[];
    }
  }, [page])

  return (
    <Flex
      flexDirection={"column"} mt={{ base: 6, lg: 0 }} mx={{ base: 5, lg: 0 }}
      background={'white'}>
      <Flex
        boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.15)"}
        flexDirection={"column"}
      >
        <Flex
          width={"full"}
          backgroundColor={"success.200"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text
            fontWeight={"semibold"}
            fontSize={{ base: "md", lg: "24px" }}
            color={"martlinegray.900"}
            py={4}
            className={poppins.className}
          >
            Product Sales
          </Text>
        </Flex>

        {isLoading && <Loader />}

        <Grid
          gridTemplateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(6, 1fr)" }}
          m={3}
          columnGap={3}
          rowGap={{ base: 3, lg: 6 }}
          placeItems={'center'}
          gridAutoColumns={'1fr'}

        >
          {isSuccess && data?.product.map((item) => (
            <ProductItem key={item._id} item={item} onItemClick={handleRouteClick} />
          ))}
        </Grid>
      </Flex>

      {/* <HStack
        justifyContent={'center'}
        mt={8}
        className={poppins.className}>
        <Button
          variant={'outline'}
          onClick={() => setPage((i) => (--i))}
          isDisabled={page === 1}>
          Prev
        </Button>
        {
          buttonRange.map((i) => {
            const selected = i === page;
            return (
              <Button
                key={Math.random() * i}
                isLoading={page === i && isFetching}
                variant={'outline'}
                color={selected ? 'white' : 'martlineorange.500'}
                background={selected ? 'martlineorange.500' : 'transparent'}
                onClick={() => setPage(i)}>
                {i}
              </Button>)
          })
        }
        <Button
          onClick={() => setPage((i) => (++i))}
          // disabled={Math.ceil(data?.total ?? 0 / 10) === page}
          isDisabled={Math.ceil(100 / 10) === page}
          variant={'outline'}>Next</Button>
      </HStack> */}
    </Flex>
  );
};

export default Products;
